import AccountLockedBanner from '@root/auth/src/components/account-locked-banner';
import Input, { TextTypes } from '@root/core/src/components/input';
import Link from '@root/core/src/components/link';
import LoaderButton from '@root/core/src/components/loader-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Toast, { errorToast } from '@root/core/src/components/toast';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useForm from '@root/core/src/hooks/use-form';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { SUPPORT_PHONE, maskedPhoneNumber } from '@root/core/src/models/phone';
import { isFormatted, isRequired } from '@root/core/src/utils/validators';
import { isMobileBrowser } from '@root/core/src/utils/detect-mobile-browser';
import { sanitize } from '@root/core/src/utils/strings';

const VERIFICATION_CODE = 'verification_code';
const INCORRECT_OTP = 'incorrect_otp';
const VERIFICATION_CODE_REGEX = new RegExp('^[0-9]{6}$');
const validations = [
  isRequired(VERIFICATION_CODE),
  isFormatted(VERIFICATION_CODE, VERIFICATION_CODE_REGEX, 'Verification code should be 6 digits in length.'),
];

/**
 * View to verify a user via their phone number with a verification code.
 * This assumes the service to verify the code is the same service as the one to generate the code; the only
 * difference being the verify payload will include the code.
 * NOTE: This component expects that the consumer has already made the initial request to generate the code!
 */
export default function AuthenticationVerification({
  loginServiceRequest, loginServicePayload, onVerification, phoneNumber,
}) {
  const { trackClick } = useAnalytics([isMobileBrowser() ? 'MOBILE' : 'DESKTOP', 'MFA_VERIFICATION']);
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [badVerifyCode, setBadVerifyCode] = useState(false);
  const [toastMessage, setToastMessage] = useState(undefined);
  const [lockedOut, setIsLockedOut] = useState(false);
  const [attemptsLeftBeforeAccountLock, setAttemptsLeftBeforeAccountLock] = useState(null);

  const TOAST_DISPLAY_DURATION_MS = 3000;

  const form = useForm({
    validations,
  });

  // NOTE: error?.additionalData is based off different response parsed specifically being used for deep linking.
  // https://github.com/root-app/root-monorepo/blob/main/web/modules/core/src/networking/network-request-controller.js
  const verifyBadCodeError = (response) => response.error?.message === INCORRECT_OTP || response.error?.additionalData?.serverResponseData?.message === INCORRECT_OTP;

  // NOTE: error responses is parsed differently for regular email password login
  // https://github.com/Root-App/root-monorepo/blob/main/web/modules/core/src/api/server-utils.js
  const verifyLockedOutError = (response) => response._error?.rawResponse?.details?.access_locked || response.error?.additionalData?.serverResponseData?.details?.accessLocked;

  const getRemainingAttempts = (response) => response._error?.rawResponse?.details?.attempts_remaining || response.error?.additionalData?.serverResponseData?.details?.attemptsRemaining;

  const verifyCode = async (values) => {
    trackClick('VERIFICATION_CODE_SUBMITTED');

    const payload = { ...loginServicePayload };
    payload.otpToken = values[VERIFICATION_CODE];
    const response = await loginServiceRequest(payload);

    if (response.isSuccess && response.data.accessToken) {
      trackClick('VERIFICATION_CODE_CONFIRMED');
      onVerification(true, response.data.accessToken);
      return;
    }

    const isLockedOut = verifyLockedOutError(response);

    if (isLockedOut) {
      setAttemptsLeftBeforeAccountLock(null);
      setIsLockedOut(true);
    } else {
      const remainingAttemptsBeforeLockout = getRemainingAttempts(response);
      setAttemptsLeftBeforeAccountLock(remainingAttemptsBeforeLockout);
      setIsLockedOut(false);
    }

    if (verifyBadCodeError(response)) {
      // The process was successful, but the code was not verified.
      setBadVerifyCode(true);
      form.setSubmitting(false);
      return;
    }

    setToastMessage({
      ...errorToast({ title: 'Request failed. Please try again.' }),
      closeAfter: TOAST_DISPLAY_DURATION_MS,
    });
    form.setSubmitting(false);
  };

  const handleResendPressed = async () => {
    trackClick('RESEND_CODE_PRESSED');

    setIsResendingCode(true);

    const response = await loginServiceRequest(loginServicePayload);

    if (!response.isSuccess) {
      setToastMessage({
        ...errorToast({ title: 'Request failed. Please try again.' }),
        closeAfter: TOAST_DISPLAY_DURATION_MS,
      });
    }

    setIsResendingCode(false);
  };

  const contactSupport = () => {
    if (isMobileBrowser()) {
      return (
        <>
          <span>Need help?</span>
          <a
            css={styles.minorLink}
            href={`tel:1 ${SUPPORT_PHONE}`}
            onClick={() => trackClick('CALL_SUPPORT')}
          >
            Contact customer service.
          </a>
        </>
      );
    }

    return (
      <span>
        Need help? Contact us at {SUPPORT_PHONE}
      </span>
    );
  };

  const scrubFunction = (newVerificationValue) => {
    // The sanitize function will restrict to just numbers and limit to 6 characters.
    // This is used instead of the inputType of 'number', as it would allow the letter 'e'.
    // The attribute inputMode should affect the keyboard displayed in mobile devices.
    return sanitize(newVerificationValue, {
      length: 6,
      pattern: /\D/,
    });
  };

  const codeFieldProps = form.getFieldProps('verification_code', { sanitize: scrubFunction });
  if (badVerifyCode && !lockedOut) {
    codeFieldProps.errorLabel = 'Verification code is invalid or expired.';
    codeFieldProps.isError = true;
  }

  return (
    <div css={styles.outerContainer}>
      <div css={styles.innerContainer}>
        <div css={styles.textContainer}>
          <h1 css={styles.heading}>
            {'Let\'s verify it\'s you.'}
          </h1>
          <p css={styles.subtext}>
            {`We just sent a verification code to the following phone number: ${maskedPhoneNumber(phoneNumber)}`}
          </p>
          <p css={styles.subtext}>
            {contactSupport()}
          </p>
        </div>
        {(lockedOut || attemptsLeftBeforeAccountLock) &&
        <AccountLockedBanner
          allowResetPassword={false}
          numberAttemptsRemaining={attemptsLeftBeforeAccountLock}
        />}
        <form
          data-testid={'login-form'}
          onSubmit={form.createSubmitHandler(verifyCode)}
        >
          <div css={styles.inputContainer}>
            <Input
              id={VERIFICATION_CODE}
              label={'Verification code'}
              {...codeFieldProps}
              inputMode={'numeric'}
              inputType={TextTypes.TEXT}
              onFocus={() => { setBadVerifyCode(false); }}
            />
          </div>
          <Link
            css={styles.primaryLink}
            onClick={handleResendPressed}
          >
            {isResendingCode ? 'Sending...' : 'Resend code'}
          </Link>
          <LoaderButton
            disabled={lockedOut}
            isLoading={form.submitting}
            loadingText={'Submitting...'}
          >
            Verify
          </LoaderButton>
        </form>
      </div>
      <Link
        css={styles.primaryLink}
        onClick={() => { onVerification(false); }}
      >
        Close
      </Link>
      <div css={styles.toastWrapper}>
        {
          toastMessage && (
            <Toast
              analyticsContext={'AUTHENTICATION_VERIFICATION'}
              toastMessage={toastMessage}
            />
          )
        }
      </div>
    </div>
  );
}

AuthenticationVerification.propTypes = {
  /** Payload to be sent on the login request **/
  loginServicePayload: PropTypes.object,
  /** Service called to verify the verification code. When the route request is successful, it needs to return an accessToken. **/
  loginServiceRequest: PropTypes.func.isRequired,
  /** Callback when verification is successful. First parameter will be true to indicate verification succeeded. **/
  onVerification: PropTypes.func.isRequired,
  /** 10 digit phone number the code was sent to **/
  phoneNumber: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  innerContainer: {
    display: 'flex',
    maxWidth: '380px',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 12,
  },
  textContainer: {
    marginBottom: 18,
  },
  heading: {
    ...Theme.heading1(),
    whiteSpace: 'pre-line',
    marginBottom: 6,
  },
  subtext: {
    ...Theme.paragraph1(),
    marginBottom: 6,
  },
  minorLink: {
    ...Theme.link(),
    color: Colors.gray50(),
  },
  primaryLink: {
    ...Theme.link(),
    marginBottom: 4,
  },
  inputContainer: {
    paddingBottom: 12,
  },
});
