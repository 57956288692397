import DobDate from '@root/core/src/models/dob-date';
import merge from '@root/vendor/lodash/merge';
import pick from '@root/vendor/lodash/pick';
import uuid from '@root/vendor/uuid/v4';
import { RootError } from '@root-common/root-errors';

export const DEFAULT_ANNUALIZED_MILEAGE = 12500;

export const DEFAULT_WEEKLY_COMMUTE_DAYS = 5;

export const PRIMARY_USAGE_PROPS = {
  annualizedMileage: 'annualizedMileage',
  commuteDistance: 'commuteDistance',
  editingMileage: 'editingMileage',
  finalAnnualizedMileage: 'finalAnnualizedMileage',
  primaryUsage: 'primaryUsage',
  removed: 'removed',
  testDriveSelected: 'testDriveSelected',
  weeklyCommuteDays: 'weeklyCommuteDays',
};

export const PRIMARY_USAGES = {
  business: 'business',
  commute: 'commute',
  occasional: 'occasional',
};

export const VEHICLE_CLASS_CODE_PRIMARY_USAGES = {
  business: 'business',
  farm: 'farm',
  pleasure: 'pleasure',
  rideshare: 'rideshare',
  work: 'work',
};

export default class Vehicle {
  static DEFAULTS = {
    annualizedMileage: undefined,
    antiTheftEquipment: null,
    commuteDistance: undefined,
    editingMileage: undefined,
    finalAnnualizedMileage: undefined,
    make: undefined,
    model: undefined,
    trim: undefined,
    garagingAddress1: undefined,
    garagingAddress2: undefined,
    garagingCity: undefined,
    garagingState: undefined,
    garagingZip: undefined,
    primaryUsage: undefined,
    purchaseDate: undefined,
    removed: false,
    selected: false,
    shortVin: undefined,
    testDriveSelected: null,
    vehicleImageUrl: undefined,
    vin: undefined,
    vinEtching: null,
    weeklyCommuteDays: undefined,
    year: undefined,
  };

  static filterKeys = (value) => pick(value, Object.keys(Vehicle.DEFAULTS));

  static getAvailableVinmasterModelYears() {
    const years = [];
    for (let i = new Date().getFullYear() + 1; i >= 1981; i--) {
      years.push(i);
    }
    return years;
  }

  static buildFromData(value = Vehicle.DEFAULTS) {
    const args = Vehicle.filterKeys(value);

    return new Vehicle(
      {
        ...args,
        id: value.id,
        createdAt: value.createdAt,
      }
    );
  }

  constructor(value = Vehicle.DEFAULTS) {
    const cid = value.id || uuid();
    const purchaseDate = typeof value.purchaseDate === 'string'
      ? new DobDate(value.purchaseDate)
      : value.purchaseDate;

    const createdAt = typeof value.createdAt === 'string'
      ? new Date(value.createdAt)
      : value.createdAt || new Date();

    const props = {
      ...Vehicle.filterKeys({
        ...Vehicle.DEFAULTS,
        ...value,
      }),
      cid,
      createdAt,
      purchaseDate,
    };

    Object.assign(this, props);
  }

  key() {
    return `${this.year} ${this.model} ${this.make} ${this.trim} ${this.vin} ${this.shortVin} ${this.cid}`;
  }

  set(key, value) {
    if (!Object.keys(Vehicle.DEFAULTS).includes(key)) {
      return this;
    }

    return Object.assign(
      new Vehicle(),
      this,
      {
        [key]: value,
      },
    );
  }

  setAttributes(value = {}) {
    return Object.assign(
      new Vehicle(),
      this,
      Vehicle.filterKeys(value),
    );
  }

  mergeVehicle(newVehicle) {
    if (newVehicle.cid === this.cid || newVehicle.vin && newVehicle.vin === this.vin) {
      return merge(
        new Vehicle(),
        Vehicle.filterKeys(this),
        newVehicle,
      );
    }

    throw new RootError({
      message: 'Attempted to merge incompatible vehicles',
      name: 'VehicleError',
    });
  }

  isComplete() {
    return this.vin;
  }

  getAvailableVin() {
    if (!this.vin && !this.shortVin) {
      throw new RootError({
        message: 'No vin or shortVin set',
        name: 'VehicleError',
      });
    }
    return !this.vin ? this.shortVin : this.vin;
  }

  makeAndModelOrVin() {
    if (this.make && this.model) {
      return `${this.make} ${this.model}`;
    }

    if (this.vin) {
      return `VIN: ${this.vin}`;
    }

    return undefined;
  }

  yearMakeAndModelOrVin() {
    if (this.year && this.make && this.model) {
      return `${this.year} ${this.make} ${this.model}`;
    }

    return this.makeAndModelOrVin();
  }

  garagingAddress1And2() {
    if (!this.garagingAddress2) {
      return this.garagingAddress1;
    }
    return `${this.garagingAddress1}, ${this.garagingAddress2}`;
  }

  serializeForSubmission() {
    return {
      annualizedMileage: this.annualizedMileage,
      antiTheftEquipment: this.antiTheftEquipment,
      commuteDistance: this.commuteDistance,
      make: this.make || '',
      model: this.model || '',
      finalAnnualizedMileage: this.finalAnnualizedMileage,
      primaryUsage: this.primaryUsage,
      purchaseDate: this.purchaseDate instanceof DobDate ? this.purchaseDate.toString() : null,
      shortVin: this.shortVin,
      vin: this.vin?.toUpperCase() || '',
      garagingAddress1: this.garagingAddress1,
      garagingAddress2: this.garagingAddress2,
      garagingCity: this.garagingCity,
      garagingState: this.garagingState,
      garagingZip: this.garagingZip,
      vinEtching: this.vinEtching,
      weeklyCommuteDays: this.weeklyCommuteDays,
      year: this.year || '',
    };
  }

  serializeForSaving() {
    return {
      ...this,
      make: this.make || '',
      model: this.model || '',
      purchaseDate: this.purchaseDate instanceof DobDate ? this.purchaseDate.toString() : null,
      garagingAddress1: this.garagingAddress1,
      garagingAddress2: this.garagingAddress2,
      garagingCity: this.garagingCity,
      garagingState: this.garagingState,
      garagingZip: this.garagingZip,
      vin: this.vin?.toUpperCase() || '',
      year: this.year || '',
      vehicleImageUrl: this.vehicleImageUrl || '',
    };
  }

  vinSubstringMatchesShortVin(vinSubstring = '') {
    for (let i = 0; i < this.shortVin?.length && i < vinSubstring.length; i++) {
      if (
        !(this.shortVin?.charAt(i) === vinSubstring.charAt(i) ||
        this.shortVin?.charAt(i) === '&' ||
        this.shortVin?.charAt(i) === '*')
      ) {
        return false;
      }
    }
    return true;
  }
}
