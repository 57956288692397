const INVALID_CHARACTERS = '\\*|@|#|\\$|\\\\|\\^|&|%|!|\\(|\\)|_|<|>|\\`|~|\\+|=|\\[|\\]|{|}|\\/|\\||"|\\?|;|:';
const DEFAULT_MAX_LENGTH = 50;

export const obfuscate = (value = '', {
  numVisible = 0,
  fillString = '*',
  minLength = 0,
} = {}) => {
  if (value === null) {
    return '';
  }
  return value.length
    ? value.slice(value.length - numVisible).padStart(Math.max(value.length, minLength), fillString)
    : value;
};

export const sanitize = (value = '', { pattern = INVALID_CHARACTERS, length = DEFAULT_MAX_LENGTH } = {}) => {
  value = value.replace(new RegExp(pattern, 'g'), '');
  value = value.substr(0, length);
  return value;
};

export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber?.length !== 10) {
    return phoneNumber;
  }

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const formatPhoneNumberWithParentheses = (phoneNumber) => {
  if (phoneNumber?.length !== 10) {
    return phoneNumber;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export function pluralize(word, count, pluralizedWord) {
  if (!pluralizedWord) {
    pluralizedWord = word + 's';
  }
  return count === 1 ? word : pluralizedWord;
}

export function toKebabCase(string) {
  return string
    .replace(/([A-Z])/g, ($1) => ' ' + $1)
    .toLowerCase()
    .split(' ')
    .filter((s) => s.length > 0)
    .join('-');
}

export function constantize(word) {
  return word?.trim().replace(/[\W_]+/g, '_').toUpperCase();
}

export const capitalize = (word) => {
  if (typeof word !== 'string') { return ''; }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export function toSentence(array) {
  if (array.length < 3) {
    return array.join(' and ');
  }

  const first = array.slice(0, -1);
  const last = array[array.length - 1];

  return first.concat([`and ${last}`]).join(', ');
}

export const obfuscateEmail = (email) => {
  if (!email || typeof email !== 'string') { return ''; }

  const [localPart, domain] = email.split('@');
  if (!localPart || !domain || localPart.length === 1) { return email; } // Return as-is if it's not a valid email format

  const numVisible = 2; // Show first two characters
  const obfuscatedLocal = localPart.slice(0, numVisible) + '*'.repeat(localPart.length - numVisible);

  return `${obfuscatedLocal}@${domain}`;
};
