import InputError from '@root/core/src/components/input-error';
import PropTypes from '@root/vendor/prop-types';
import React, { forwardRef, useRef } from '@root/vendor/react';
import ZIndex from '@root/core/src/utils/z-index';
import useInputFocusOnMount from '@root/core/src/hooks/use-input-focus-on-mount';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export const TextTypes = {
  EMAIL: 'email',
  PASSWORD: 'password',
  TEL: 'tel',
  TEXT: 'text',
  NUMBER: 'number',
};

function Input({
  errorLabel,
  focusOnMount = false,
  icon,
  rightIconComponent,
  id,
  inputRef,
  inputStyle,
  inputType = TextTypes.TEXT,
  label,
  labelStyle,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  prefix,
  // set forceErrorStyle true to give the control Error styling, even if there is no error message
  forceErrorStyle = false,
  value,
  wrapperStyle,
  ...props
}) {
  delete props.isError;
  const ref = useRef(null);
  inputRef = inputRef || ref;
  const inputId = id || label.toLowerCase().replace(/ /g, '-') + '-input';
  const labelId = `${inputId}-label`.replace(/ /g, '-');
  const isFocused = inputRef.current && document?.activeElement === inputRef.current;
  const shouldIncludePrefix = prefix && (isFocused || value);

  const inputStyles = [styles.input, inputStyle];
  const iconStyles = [styles.icon];
  const wrapperStyles = [styles.wrapper, wrapperStyle];
  const labelStyles = [styles.labelFull, labelStyle];

  if (value !== '' && value !== undefined || props.defaultValue !== '' && props.defaultValue !== undefined) {
    labelStyles.push(styles.labelSmall);
  }

  if (inputType === TextTypes.NUMBER) {
    inputStyles.push(styles.inputHideSpinners);
  }

  if (errorLabel || forceErrorStyle) {
    inputStyles.push(styles.errorInput);
  }

  if (icon) {
    inputStyles.push(styles.iconInput);
    labelStyles.push(styles.iconLabel);
    if (props.disabled) { //eslint-disable-line react/prop-types
      iconStyles.push(styles.disabledIcon);
    }
  }

  if (shouldIncludePrefix) {
    inputStyles.push(styles.inputPrefix);
  }

  useInputFocusOnMount(focusOnMount, inputRef);

  return (
    <>
      <div css={wrapperStyles}>
        {icon && (
          <div
            css={iconStyles}
            data-testid={'input-icon'}
          >
            {icon}
          </div>
        )}
        {shouldIncludePrefix && (
          <span css={styles.prefix}>
            {prefix}
          </span>
        )}
        <input
          {...props}
          aria-labelledby={labelId}
          css={inputStyles}
          data-testid={name ? 'Input#' + name : null}
          id={inputId}
          name={name}
          onBlur={(e) => onBlur && onBlur(e.target.value)}
          onChange={(e) => onChange(e.target.value)}
          onFocus={(e) => onFocus && onFocus(e.target.value)}
          placeholder={placeholder}
          ref={inputRef}
          role={inputType === TextTypes.NUMBER ? 'spinbutton' : 'textbox'}
          type={inputType}
          value={value}
        />
        <label
          css={labelStyles}
          htmlFor={inputId}
          id={labelId}
        >
          {label}
        </label>
        {rightIconComponent && (
          <div css={styles.rightComponentContainer}>
            {rightIconComponent}
          </div>
        )}
      </div>
      <InputError
        message={errorLabel}
      />
    </>
  );
}

Input.propTypes = {
  defaultValue: PropTypes.string,
  errorLabel: PropTypes.string,
  focusOnMount: PropTypes.bool,
  forceErrorStyle: PropTypes.bool,
  icon: PropTypes.node,
  id: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  inputType: PropTypes.oneOf(Object.values(TextTypes)),
  isError: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  rightIconComponent: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default forwardRef((props, ref) => (
  <Input
    {...props}
    inputRef={ref}
  />
));

const labelSmallStyle = {
  ...Theme.inputLabel(),
  top: 3,
  transform: 'translateY(10px)',
  alignItems: 'flex-start',
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    height: 65,
    position: 'relative',
    zIndex: ZIndex.INPUT,
  },
  input: {
    ...Theme.input(),
    caretColor: Colors.rootOrange(),
    height: 65,
    width: '100%',
    paddingLeft: 15,
    paddingTop: 30,
    paddingBottom: 7,
    ...Theme.roundedCorners(),
    border: `1px solid ${Colors.gray40()}`,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    transition: 'border-color 200ms ease-in',
    '::placeholder': {
      color: 'transparent',
    },
    ':focus': {
      outline: 'none',
      borderColor: Colors.black(),
    },
    ':focus::placeholder': {
      color: Colors.gray40(),
    },
    ':focus + label': labelSmallStyle,
    ':disabled': {
      border: 'none',
      background: Colors.gray10(),
      WebkitTextFillColor: Colors.gray50(),
      WebkitOpacity: '1',
      color: Colors.gray50(),
    },
  },
  inputPrefix: {
    textIndent: '0.7em',
  },
  inputHideSpinners: {
    '::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },
  labelFull: {
    ...Theme.input({
      placeholder: true,
    }),
    pointerEvents: 'none',
    paddingLeft: 15,
    marginLeft: 1,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    display: 'flex',
    alignItems: 'center',
    transition: 'all 150ms ease-out',
  },
  labelSmall: labelSmallStyle,
  errorInput: {
    borderColor: Colors.error(),
    color: Colors.error(),
    ':focus': {
      borderColor: Colors.error(),
    },
  },
  icon: {
    position: 'relative',
    zIndex: 1,
    top: 20,
    left: 18,
    width: 24,
    '> svg': {
      height: 24,
      width: 24,
    },
  },
  disabledIcon: {
    path: {
      fill: Colors.gray40(),
    },
  },
  iconInput: {
    paddingLeft: 58,
  },
  iconLabel: {
    paddingLeft: 58,
  },
  prefix: {
    position: 'absolute',
    top: 28,
    left: 15,
    zIndex: 1,
  },
  rightComponentContainer: {
    position: 'relative',
    zIndex: 1,
    right: 12,
    float: 'right',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});
