import InfoBanner, { Variants } from '@root/core/src/components/info-banner';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { SUPPORT_PHONE } from '@root/core/src/models/phone';
import { isMobileBrowser } from '@root/core/src/utils/detect-mobile-browser';
import { useHistory } from '@root/vendor/react-router';

/**
 * Banner component to display an error message for login attempts / account lockout.
 *
 * @param {boolean} allowResetPassword - If true and numberAttemptsRemaining > 0, the reset password link will be displayed.
 * @param {number} numberAttemptsRemaining number of attempts remaining before the account is locked.  If it is not valued or 0,
 *  the "Account Locked" message will be displayed.
 * @returns {JSX.Element} The rendered error message component.
 */
export default function AccountLockedBanner({
  numberAttemptsRemaining,
  allowResetPassword = true,
}) {
  const { trackClick } = useAnalytics('ACCOUNT_LOCKED_BANNER');

  const history = useHistory();

  const handleResetPassword = () => {
    trackClick('RESET_PASSWORD');
    history.push('/password-reset');
  };

  let variant, errorText;

  if (!numberAttemptsRemaining || numberAttemptsRemaining <= 0) {
    variant = Variants.ERROR;
    errorText = (
      <div>
        <h4>Account locked</h4>
        Your account was locked due to several failed login attempts. Please try again in 24 hours or call&nbsp;
        {isMobileBrowser() ? (
          <a
            css={styles.errorLink}
            href={`tel:1 ${SUPPORT_PHONE}`}
            onClick={() => trackClick('CALL_SUPPORT')}
          >
            {SUPPORT_PHONE}
          </a>
        ) : (
          <span>
            {SUPPORT_PHONE}
          </span>
        )}
        {/* The &nbsp; below and above are required for proper formatting of the text spaces around the phone number  */}
        &nbsp;to unlock your account and reset your password.
      </div>
    );
  } else {
    variant = Variants.ALERT;
    errorText = (
      <div>You have {numberAttemptsRemaining} attempts left before your account is locked. {
        allowResetPassword &&
        <>Try again or&nbsp;
          <a
            css={styles.errorLink}
            onClick={() => handleResetPassword()}
          >reset your password.
          </a>
        </>}

      </div>);
  }

  return (
    <div css={[styles.container]}>
      <InfoBanner
        text={errorText}
        variant={variant}
      />
    </div>
  );
}

AccountLockedBanner.propTypes = {
  allowResetPassword: PropTypes.bool,
  numberAttemptsRemaining: PropTypes.number,
};

const styles = StyleSheet.create({
  errorLink: {
    ...Theme.link(),
    color: Colors.red(),
  },
  container: {
    marginBottom: 24,
  },
});
