import Colors from '@root/brand/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import alertIcon from '@root/core/src/assets/alert-filled.svg';
import checkCircleFilledIcon from '@root/core/src/assets/checkmark-filled-green.svg';
import informationIcon from '@root/core/src/assets/information.svg';
import lockIcon from '@root/core/src/assets/lock.svg';
import warningIcon from '@root/core/src/assets/warning.svg';
import { StyleSheet } from '@root/core/src/utils/styles';
import { Theme } from '@root/core/src/utils/styles';

export const Variants = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error',
  ALERT: 'alert',
};

const VARIANT_CONFIG = {
  [Variants.INFO]: {
    icon: {
      src: informationIcon,
      alt: 'Information icon',
    },
    container: {
      styles: {
        backgroundColor: Colors.blueTint(),
        borderColor: Colors.blue(),
      },
    },
  },
  [Variants.SUCCESS]: {
    icon: {
      src: checkCircleFilledIcon,
      alt: 'Check mark',
    },
    container: {
      styles: {
        backgroundColor: Colors.greenTint(),
        borderColor: Colors.green(),
      },
    },
  },
  [Variants.WARNING]: {
    icon: {
      src: warningIcon,
      alt: 'Warning symbol',
    },
    container: {
      styles: {
        backgroundColor: Colors.warnBackground(),
        borderColor: Colors.warn(),
      },
    },
  },
  [Variants.ERROR]: {
    icon: {
      src: lockIcon,
      alt: 'Lock symbol',
    },
    container: {
      styles: {
        backgroundColor: Colors.redTint(),
        borderColor: Colors.red(),
        textColor: Colors.red(),
      },
    },
  },
  [Variants.ALERT]: {
    icon: {
      src: alertIcon,
      alt: 'Alert symbol',
    },
    container: {
      styles: {
        backgroundColor: Colors.redTint(),
        borderColor: Colors.red(),
        textColor: Colors.red(),
      },
    },
  },
};

export default function InfoBanner({
  text,
  variant,
  className,
  cssOverrides = [],
}) {
  const variantConfig = VARIANT_CONFIG[variant];
  const errorVariantStyling = variant === Variants.ERROR || variant === Variants.ALERT;

  return (
    <div
      className={className}
      css={[styles.container, variantConfig.container.styles, ...cssOverrides]}
    >
      <img
        alt={variantConfig.icon.alt}
        css={[
          styles.leftIcon,
          errorVariantStyling && styles.errorIcon,
        ]}
        src={variantConfig.icon.src}
      />
      <div css={styles.message}>
        {text}
      </div>
    </div>
  );
}

const PADDING = 10;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexFlow: 'row',
    padding: PADDING,
    borderStyle: 'solid',
    borderRadius: 4,
    borderWidth: 1,
  },
  message: {
    ...Theme.heading5(),
  },
  leftIcon: {
    alignSelf: 'flex-start',
    paddingRight: PADDING,
  },
  errorIcon: {
    // The color below corresponds to hex #EA0000
    filter: 'invert(21%) sepia(100%) saturate(7472%) hue-rotate(356deg) brightness(97%) contrast(104%)',
  },
});

InfoBanner.propTypes = {
  className: PropTypes.string,
  cssOverrides: PropTypes.array,
  text: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([Variants.SUCCESS, Variants.WARNING, Variants.INFO, Variants.ERROR, Variants.ALERT]),
};

InfoBanner.Variants = Variants;
