export default class Colors {
  static rootOrange() {
    return '#ff5715';
  }

  static white() {
    return '#ffffff';
  }

  static nearWhite() {
    return '#fafafa';
  }

  static gray10() {
    return '#f4f4f6';
  }

  static gray20() {
    return '#e4e4e4';
  }

  static gray30() {
    return '#dadada';
  }

  static gray40() {
    return '#bbbbbb';
  }

  static gray50() {
    return '#767676';
  }

  static gray60() {
    return '#5f5f62';
  }

  static nearBlack() {
    return '#1d1d21';
  }

  static black() {
    return '#000000';
  }

  static darkGreen() {
    return '#006464';
  }

  static lightGreen() {
    return '#72cac8';
  }

  static darkBlue() {
    return '#323c64';
  }

  static lightBlue() {
    return '#e1f1ff';
  }

  static darkYellow() {
    return '#da9514';
  }

  static lightYellow() {
    return '#feefc8';
  }

  static darkPink() {
    return '#9e3a46';
  }

  static lightPink() {
    return '#faf0f5';
  }

  static facebookBlue() {
    return '#4267b2';
  }

  static error() {
    return this.red();
  }

  static errorBackground() {
    return this.redTint();
  }

  static success() {
    return this.green();
  }

  static successBackground() {
    return this.greenTint();
  }

  static warn() {
    return this.yellow();
  }

  static warnBackground() {
    return this.yellowTint();
  }

  static red() {
    return '#ea0000';
  }

  static redTint() {
    return '#fef2f2';
  }

  static green() {
    return '#00aa00';
  }

  static greenTint() {
    return '#f2fbf2';
  }

  static blue() {
    return '#005BC6';
  }

  static blueTint() {
    return '#F0F8FF';
  }

  static yellow() {
    return '#ffcd00';
  }

  static yellowTint() {
    return '#fffdf2';
  }

  static toRgb = (value) => {
    const raw = value?.replace('#', '') || '';
    const color = raw.length === 3 ? [...raw].map((s) => s + s).join('') : raw.padEnd(6, '0');
    return `${parseInt(color.substring(0, 2), 16)},${parseInt(color.substring(2, 4), 16)},${parseInt(color.substring(4, 6), 16)}`;
  };

  static toRgba = (value, opacity = 1) => {
    return `rgba(${Colors.toRgb(value)},${opacity})`;
  };

  // EVERYTHING BELOW THIS LINE IS DEPRECATED

  static DEPRECATED_mantisGreen() {
    return '#72cf61';
  }

  static DEPRECATED_backgroundPurple() {
    return '#311c3a';
  }

  static DEPRECATED_purple() {
    return '#8000FF';
  }

  static DEPRECATED_green() {
    return '#00a778';
  }

  static DEPRECATED_halfBakedGradient() {
    return ['rgba(122, 198, 196, 0.0001)', 'rgba(122, 198, 196, 1)'];
  }

  static DEPRECATED_japaneseLaurel() {
    return '#00AA00';
  }

  static DEPRECATED_rootOrangeHover() {
    return '#E54E13';
  }

  static DEPRECATED_rootOrangeButtonHover() {
    return '#FB4700';
  }

  static DEPRECATED_rootOrangeOpacity(opacity = 1.0) {
    return `rgba(255, 87, 21, ${opacity})`;
  }

  static DEPRECATED_aqua() {
    return 'rgba(222, 241, 242, 0.5)';
  }

  static DEPRECATED_seafoam() {
    return '#72cac8';
  }

  static DEPRECATED_shark(opacity = 1.0) {
    return `rgba(29,29,33, ${opacity})`; // #1d1d21
  }

  static DEPRECATED_darkSeafoam() {
    return '#299C99';
  }

  static DEPRECATED_lightSeafoam() {
    return 'rgba(41, 156, 153, 0.1)';
  }

  static DEPRECATED_transparentWhite(opacity = 0.8) {
    return `rgba(255, 255, 255, ${opacity})`;
  }

  static DEPRECATED_ming() {
    return '#366181';
  }

  static DEPRECATED_rootOrangeGradient() {
    return ['#FF7C1E', this.rootOrange()];
  }

  static DEPRECATED_sunglowGradient() {
    return ['#FABE51', this.rootOrange()];
  }

  static DEPRECATED_grayGradient() {
    return ['rgba(228, 228, 228, 1)', 'rgba(228, 228, 228, 0.0001)'];
  }

  static DEPRECATED_shadow(opacity) {
    return `rgba(0, 0, 0, ${opacity})`;
  }

  static DEPRECATED_blackShadow() {
    return this.DEPRECATED_shadow(0.1);
  }

  static DEPRECATED_overlayShadow() {
    return this.DEPRECATED_shadow(0.4);
  }

  static DEPRECATED_overlayShadowDark() {
    return 'rgba(64, 64, 64, 0.8)';
  }

  static DEPRECATED_seafoamBlueGradient() {
    return [this.DEPRECATED_seafoam(), this.DEPRECATED_mantisGreen()];
  }

  static DEPRECATED_bonJour() {
    return '#E0DFE0';
  }

  static DEPRECATED_amaranth() {
    return '#e92560';
  }

  static DEPRECATED_guardsmanRed() {
    return '#CC0000';
  }
}

