import PropTypes from '@root/vendor/prop-types';
import React, { useRef } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import SliderAnimator from '@root/core/src/components/slider-animator';
import ZIndex from '@root/core/src/utils/z-index';
import closeButtonIcon from '@root/core/src/assets/closeModal.svg';
import closeCircleIcon from '@root/core/src/assets/closeCircle.svg';
import useModal from '@root/core/src/hooks/use-modal';
import usePortal from '@root/core/src/hooks/use-portal';
import { Colors, StyleSheet, styled } from '@root/core/src/utils/styles';

export default function Modal({
  children,
  name,
  isShowing,
  onCancel,
  responsiveCloseButton = true,
  showCloseButton = true,
  styleOverrides = {},
}) {
  const portalRef = useRef();

  const renderPortal = usePortal(name);
  useModal(onCancel, portalRef);

  return renderPortal(
    <SliderAnimator>
      {isShowing && (
        <div
          css={[styles.cardWrapper, styleOverrides.cardWrapper]}
          data-testid={`${name}-wrapper`}
        >
          <div
            css={[styles.outerContent, styleOverrides.outerContent]}
            ref={portalRef}
          >
            {showCloseButton &&
              <CloseButton
                aria-label={'close-button'}
                data-testid={`${name}-close-button`}
                onClick={onCancel}
                responsiveCloseButton={responsiveCloseButton}
              >
                <img
                  className={'desktop-icon'}
                  css={styleOverrides.closeDesktopButton}
                  src={closeButtonIcon}
                />
                <img
                  className={'mobile-icon'}
                  src={closeCircleIcon}
                />
              </CloseButton>
            }
            <InnerContent
              css={[styles.innerContent, styleOverrides.innerContent]}
              responsiveCloseButton={responsiveCloseButton}
            >
              {children}
            </InnerContent>
          </div>
        </div>
      )}
    </SliderAnimator>
  );
}

const styles = StyleSheet.create({
  cardWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    zIndex: ZIndex.MODAL,
    ...Responsive.sm({
      alignItems: 'center',
    }),
  },
  outerContent: {
    backgroundColor: Colors.white(),
    padding: 15,
    borderRadius: '20px 20px 0 0',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    width: '100vw',
    ...Responsive.sm({
      padding: 35,
      borderRadius: 20,
      width: 'auto',
    }),
  },
  innerContent: {
    maxWidth: 500,
    maxHeight: '100vh',
    padding: 10,
    overflow: 'auto',
    ...Responsive.sm({
      padding: '40px 80px',
    }),
  },
});

Modal.propTypes = {
  children: PropTypes.node,
  isShowing: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  styleOverrides: PropTypes.shape({
    outerContent: PropTypes.object,
    innerContent: PropTypes.object,
  }),
};

const CloseButton = styled.button({
  background: 'none',
  padding: 0,
  border: 'none',
  cursor: 'pointer',
  alignSelf: 'flex-end',
  height: 64,
  '& .desktop-icon': {
    display: 'initial',
  },
  '& .mobile-icon': {
    display: 'none',
  },
}, (props) => {
  if (!props.responsiveCloseButton) { return {}; }

  return {
    transform: 'translateY(-100px)',
    alignSelf: 'center',
    position: 'absolute',
    '& .desktop-icon': {
      display: 'none',
    },
    '& .mobile-icon': {
      display: 'initial',
    },
    ...Responsive.sm({
      alignSelf: 'flex-end',
      height: 24,
      transform: 'none',
      '& .desktop-icon': {
        display: 'initial',
      },
      '& .mobile-icon': {
        display: 'none',
      },
    }),
  };
});

const InnerContent = styled.div({}, (props) => ({
  padding: props.responsiveCloseButton ? 0 : '26px 0 0 0',
}));

export const commonStyleOverrides = {
  outerContent: {
    ...Responsive.sm({
      borderRadius: 18,
      padding: 24,
    }),
  },
  innerContent: {
    ...Responsive.sm({
      maxWidth: '380px',
      padding: 10,
      paddingTop: 40,
    }),
  },
};
