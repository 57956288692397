import environment from '@root/core/src/utils/environment';

export const TEAMS = {
  ONBOARDING: 'onboarding_team',
};

const HOSTNAMES = {
  JOINROOT: 'www.joinroot.com',
};

export default function mapUrlToEngTeam({ hostname }) {
  if (hostname === HOSTNAMES.JOINROOT) {
    return _findTeamForJoinRoot();
  }

  return _findTeamForOtherHostname();
}

function _findTeamForJoinRoot() {
  return TEAMS.ONBOARDING;
}

function _findTeamForOtherHostname() {
  return environment.sentryTeam || TEAMS.ONBOARDING;
}
