import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import closeIcon from '@root/core/src/assets/closeButton.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const TOAST_WIDTH = 300;

export function successToast({ message, title = 'Success' }) {
  return {
    message,
    title,
    type: TOAST_TYPES.SUCCESS,
  };
}

export function errorToast({ message, title = 'Error' }) {
  return {
    message,
    title,
    type: TOAST_TYPES.ERROR,
  };
}

export default function Toast({
  analyticsContext, toastMessage, cssOverrides = {}, closeIconOverride, closeCssOverrides,
}) {
  const [currentMessage, setCurrentMessage] = useState(toastMessage);

  const { trackClick, trackEvent } = useAnalytics(`${analyticsContext}`, false);

  const closeMessage = () => {
    setCurrentMessage(null);
  };

  useEffect(() => {
    if (toastMessage) {
      trackEvent(`${toastMessage.type}_TOAST_VIEWED`);
      const autoClose = toastMessage.type === TOAST_TYPES.SUCCESS || toastMessage.closeAfter;
      setCurrentMessage({
        ...toastMessage,
        autoClose,
        closeAfter: toastMessage.closeAfter || 10000,
      });
    }
  }, [toastMessage, trackEvent]);

  useEffect(() => {
    if (currentMessage && currentMessage.autoClose) {
      const timer = setTimeout(() => {
        closeMessage();
      }, currentMessage.closeAfter);

      return () => clearTimeout(timer);
    }
  }, [currentMessage]);

  const handleCloseClicked = () => {
    trackClick(`${toastMessage.type}_TOAST_DISMISS`);
    closeMessage();
  };

  const showCloseButton = !currentMessage?.autoClose || currentMessage?.forceShowCloseButton;

  return (
    <div
      className={currentMessage ? 'active' : null}
      css={styles.container}
      data-testid={'toast'}
      data-toastactive={!!currentMessage}
    >
      <div
        css={[styles.toast, currentMessage?.type === TOAST_TYPES.ERROR ? styles.error : null, cssOverrides]}
        data-toasttype={currentMessage?.type}
      >
        {
          showCloseButton && (
            <button
              css={[styles.close, closeCssOverrides]}
              data-testid={'close-button'}
              onClick={handleCloseClicked}
            >
              {
                closeIconOverride ? closeIconOverride : (
                  <img
                    src={closeIcon}
                    style={{
                      border: 'none',
                      height: 16,
                      width: 16,
                    }}
                  />
                )
              }
            </button>
          )
        }
        <div css={styles.content}>
          <p css={styles.title}>{currentMessage?.title}</p>
          {!!currentMessage?.message && <p css={[styles.message, currentMessage?.title ? null : styles.messageWithoutTitle]}>{currentMessage.message}</p>}
        </div>
      </div>
    </div>

  );
}

Toast.propTypes = {
  analyticsContext: PropTypes.string.isRequired,
  closeCssOverrides: PropTypes.object,
  closeIconOverride: PropTypes.node,
  cssOverrides: PropTypes.object,
  toastMessage: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(TOAST_TYPES)),
    message: PropTypes.string,
    title: PropTypes.string,
    closeAfter: PropTypes.number,
    forceShowCloseButton: PropTypes.bool,
  }),
};

const styles = StyleSheet.create({
  container: {
    fontSize: 14,
    color: Colors.nearWhite(),
    boxSizing: 'border-box',
    position: 'fixed',
    zIndex: 9999,
    right: 12,
    bottom: -300,
    transition: 'bottom 300ms ease-in',
    '&.active': {
      bottom: 12,
      transition: 'bottom 300ms ease-in',
    },
  },
  toast: {
    background: Colors.nearBlack(),
    position: 'relative',
    overflow: 'hidden',
    marginBottom: 15,
    padding: 16,
    width: TOAST_WIDTH,
    transition: '.3s ease',
    pointerEvents: 'auto',
    borderRadius: 4,
    borderLeft: '4px solid #00A2E8',
    opacity: .9,
    backgroundPosition: 15,
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignContent: 'stretch',
  },
  error: {
    borderLeft: `4px solid ${Colors.rootOrange()}`,
  },
  close: {
    position: 'absolute',
    right: 8,
    top: 4,
    padding: 0,
    background: 'none',
    fontWeight: 700,
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '28px',
    marginBottom: 0,
  },
  message: {
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: '15px',
    marginTop: 10,
    marginBottom: 0,
  },
  messageWithoutTitle: {
    marginTop: 0,
  },
});
